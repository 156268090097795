import React, { useRef, useEffect, useState } from 'react';
import { Card, CardDeck, Col, Container, Row } from 'react-bootstrap';

import ParallaxSection from './ParallaxSection';
import * as config from '../config.json';
import { FirebaseContext } from '../firebase';
import parse from 'html-react-parser';
//import DonateMethod from '../models/DonateMethod';


const DonatePage: React.FC = () => {

  const firebaseContext = React.useContext( FirebaseContext );
  const [ donations, setDonations ] = useState( "" );
  const [ blurb, setBlurb ] = useState( "" );

  useEffect( () => {
    return firebaseContext?.subscribeToDonationMethods(
      data => {
	  	setDonations( JSON.stringify( data ) );		// XXX wtf?
      },
      err => {
        console.log( err );
      },
    );
  }, [ firebaseContext ] );



	if( blurb == "" ) {
		firebaseContext?.subscribeToRootData( "page-details/donate", data => {
			setBlurb( data[ "blurb" ] );
		}, console.error );
	}

  const donationCards = JSON.parse( donations || "[]" ).map( donation => {
    return (
      <Card key={donation.name}>
        <Card.Body>
          <a href={donation.url} target="_blank" >
            <Row className="align-items-center text-center" style={{ height: '100%' }}>
              <Col>
                <img
                  className="card-block stretched-link text-decoration-none"
                  style={{ height: '5rem', width: 'auto', maxWidth: '100%' }}
                  src={
                    'https://storage.googleapis.com/se-website-fe4a4.appspot.com/images/icons/icon-' +
                    donation.name.toLowerCase().replace( /[^-._0-9a-z]/g, '') +
                    '.svg'
                  }
                />
                <br />
                <br />
                Donate with {donation.name}
              </Col>
            </Row>
          </a>
        </Card.Body>
      </Card>
    );
  });

  return (
    <div className="container-fluid p-0 bg-light">

      <Container>

		<Row>
		  <Col className="p-0">
			<ParallaxSection
			  backgroundSource={ config.googleCloudBaseUrl + config.bannerFolder + "donate.webp" }

			  wrapInContainer={false}
			  overlayOpacity={0}
			>
			  <Container className="align-middle">
				<Row className="align-items-end " style={{ minHeight: '400px' }}>
				  <Col className="bg-primary text-light px-4 py-2" sm="auto">
					<h1>Donate</h1>
				  </Col>
				</Row>
			  </Container>
			</ParallaxSection>
		  </Col>
		</Row>

		<br/>
		<br/>
        <p>
			{ parse( blurb ) }
        </p>

		{donationCards}

		<br/>
		<br/>
		<br/>

      </Container>

    </div>
  );

};

export default DonatePage;
