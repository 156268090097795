
export default class DonationMethod {
	name: string;
	url: string;
	order: number;

	constructor() {
		this.name = '';
		this.url = '';
		this.order = 0;
	}
}
