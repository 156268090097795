import React from 'react';
import ErrorPage from './ErrorPage';

const PageNotFound: React.FC = () => {
  return (
    <ErrorPage
      title="Page Not Found"
      message="Please let us know how you navigated to this error so we can attempt to fix it. Thanks!"
    />
  );
};

export default PageNotFound;
