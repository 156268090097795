import React, { useEffect, useState } from 'react';
import { Card, CardDeck, Col, Container, Row } from 'react-bootstrap';

import { FirebaseContext } from '../firebase';
import parse from 'html-react-parser';

import SEINewsFeed from './SEINewsFeed';


interface DonationProvider {
  name: string;
  url: string;
}

const SEIMainPage: React.FC = () => {

	const firebaseContext = React.useContext( FirebaseContext );

	const [ donationMethods, setDonationMethods ] = useState( "[]" );
	const [ pageData, setPageData ] = useState( "" );

	const log = s => console.log( s );

	if( donationMethods == "[]" ) {
		firebaseContext?.subscribeToRootData( "donation-methods", data => {
			data = Object.values( data );	// convert to array & discard keys
			data.sort( ( a, b ) => a.order - b.order );
			setDonationMethods( JSON.stringify( Object.values( data ) ) );
		}, console.error );
	}

	if( pageData == "" ) {
		firebaseContext?.subscribeToRootData( "sei-page", data => {
			setPageData( JSON.stringify( data ) );
		}, console.error );
	}

	const donationCards = JSON.parse( donationMethods || "[]" ).map( donation => {
		return (
		  <Card key={donation.name}>
			<Card.Body>
			  <a href={donation.url} target="_blank">
				<Row className="align-items-center text-center" style={{ height: '100%' }}>
				  <Col>
					<img
					  className="card-block stretched-link text-decoration-none"
					  style={{ height: '5rem', width: 'auto', maxWidth: '100%' }}
					  src={
						'https://storage.googleapis.com/se-website-fe4a4.appspot.com/images/icons/icon-' +
						donation.name.toLowerCase().replace( /[^-._0-9a-z]/g, '') +
						'.svg'
					  }
					/>
					<br />
					<br />
					Donate with {donation.name}
				  </Col>
				</Row>
			  </a>
			</Card.Body>
		  </Card>
		);
	});


	let pd = {
		contact: { body: "", heading: "" },
		donate: { body: "", heading: "" },
		people: { body: "", heading: "" },
		purpose: { body: "", heading: "" },
		welcome: { body: "", heading: "" },
	};
	if( pageData )
		pd = JSON.parse( pageData );

	return (
		<div className="container-fluid p-0 bg-light">
		  <img
			src="https://storage.googleapis.com/se-website-fe4a4.appspot.com/images/banners/sei-banner.webp"
			style={{ width: '100%', height: 'auto' }}
		  />

		  <Container>
			<h2 className="my-3">{ parse( pd.welcome.heading) }</h2>
			<p>
				{ parse( pd.welcome.body ) }
			</p>
		  </Container>

		  <hr />

		  <SEINewsFeed />

		  <hr />

		  <Container>
			<h2 className="mb-3">{ parse( pd.donate.heading ) }</h2>
			<p>
				{ parse( pd.donate.body )  }
			</p>
			<CardDeck>
				{donationCards}
			</CardDeck>
		  </Container>

		  <hr />

		  <Container>
			<h2 className="mb-3">{ parse( pd.purpose.heading ) }</h2>
			<p>
				{ parse( pd.purpose.body )  }
			</p>
		  </Container>

		  <hr />

		  <Container>
			<h2 className="mb-3">{ parse( pd.people.heading ) }</h2>
			<p>
				{ parse( pd.people.body )  }
			</p>
		  </Container>

		  <hr />

		  <Container>
			<h2 className="mb-3">{ parse( pd.contact.heading ) }</h2>
			<p>
				{ parse( pd.contact.body ) }
			</p>
		  </Container>

		</div>
	);
};

export default SEIMainPage;
